import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 5,
  boxShadow: 24,
  p: 3,
};

export default function BasicApproveData({ openApprove, setOpenApprove, approveId, handleCloseApprove, setId, getData }) {
  const [textVal, setTextVal] = React.useState('');  
  const [intialPendingData ,setInitialPendingData] = React.useState([]);
  const token = localStorage.getItem('accessToken');
  const handleCloseModal = () => {
    // setTextVal(''); 
    window.location.reload();
    // Set text to an empty string when the modal is closed
    handleCloseApprove();
  };

  const handleInputChange = (e) => {
    let inputValue = e.target.value;
    // Replace any non-digit character with an empty string
    inputValue = inputValue.replace(/\D/g, '');
    // Ensure the input is not empty
    if (inputValue === '') {
      setTextVal('');
    } else if (parseInt(inputValue) >= 1) {
      // If input is a number greater than or equal to 1
      setTextVal(inputValue);
    } else {
      // If input is less than 1, set to 1
      setTextVal('1');
    }
  };


  const GetTotalAndPendingAmount = async (id) => {

    try {
      const resultPendingAmount = await axios.get(
        `${process.env.REACT_APP_API}admin/get_project_payment_info_by_id/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-api-key': process.env.REACT_APP_API_KEY,
            platform: process.env.REACT_APP_platform,
          },
        }
      );

      console.log(resultPendingAmount, "Result Pending Data_________");

      if (resultPendingAmount?.data?.status) {
          setInitialPendingData(resultPendingAmount?.data?.data);
          setTextVal(resultPendingAmount?.data?.data.totalAmount);

        //  setIntialPaymentStatus(resultPendingAmount?.data?.data);
      }

    } catch (error) {
      console.log(error);
    }

  };

  useEffect(() => {

    GetTotalAndPendingAmount(approveId);

  }, [approveId]);

  const addPosts = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}admin/add_admin_approve_unapprove/${approveId}`,
        {
          isApproved: "1",
          amount: textVal
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-api-key': process.env.REACT_APP_API_KEY,
            platform: process.env.REACT_APP_platform,
          },
        }
      );

      if (res?.data?.status) {
            setTextVal('');
            getData();
            handleCloseApprove();
      }

    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Modal
        open={openApprove}
        onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <p style={{ fontWeight: 'bold' }}> 
            {/* {intialPendingData?.isTotalAmount === true ? 'Amount Added Already' : "Amount" } */}
             Amount  </p>
            {/* <input
              id='outlined-basic'
              placeholder='Enter Amount'
              variant='outlined'
              style={{ marginBottom: '20px', outline: 'none' }}
              value={textVal}
              onChange={handleInputChange}
            /> */}

            {intialPendingData?.isTotalAmount === true ? <>        
                <input                
                  id='outlined-basic'
                  placeholder='Enter Amount'
                  variant='outlined'
                  style={{ marginBottom: '10px', outline: 'none' }}
                  value={textVal}
                  onChange={handleInputChange}
                  disabled={intialPendingData?.isTotalAmount}
              /> </> : 
              <>
                <input                
                  id='outlined-basic'
                  placeholder='Enter Amount'
                  variant='outlined'
                  style={{ marginBottom: '10px', outline: 'none' }}
                  value={textVal}
                  onChange={handleInputChange}
              />
              </> }


            <div style={{ width: '100%' }}>
              <Button
                variant='contained'
                
                onClick={() => addPosts()}
                // disabled={intialPendingData?.isTotalAmount}
              >
                Pay And Approve
              </Button>

              <Button
                
                variant='contained'
                style={{ width: '20%', marginLeft: '5px' }}
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
